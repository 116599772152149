var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-blog-list-item",style:(_vm.computedStyles),on:{"mouseenter":function($event){_vm.showItemOverlay = true},"mouseleave":function($event){_vm.showItemOverlay = false}}},[_c('Transition',{attrs:{"name":"fade"}},[_c('BlockBlogListItemOverlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItemOverlay),expression:"showItemOverlay"}],on:{"edit-post":function($event){return _vm.$emit('post-click')}}})],1),(_vm.showCoverImage)?_c('div',{staticClass:"block-blog-list-item__cover-image-container"},[_c('div',{staticClass:"block-blog-list-item__cover-image-wrapper"},[_c('img',{staticClass:"block-blog-list-item__cover-image",attrs:{"data-qa":"blog-list-item-image","src":_vm.post.customData.coverImage.src,"alt":_vm.post.customData.coverImage.alt},on:{"click":function($event){return _vm.$emit('post-click')}}})])]):_vm._e(),_c('BlockBlogListItemCategories',{directives:[{name:"show",rawName:"v-show",value:(_vm.shownItems.categories && _vm.post.customData.categories.length),expression:"shownItems.categories && post.customData.categories.length"}],staticClass:"font-secondary",attrs:{"categories":_vm.post.customData.categories},on:{"filter-category":function (category) { return _vm.$emit('filter-category', category); }}}),_c('div',{staticClass:"block-blog-list-item__content",on:{"click":function($event){return _vm.$emit('post-click')}}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.shownItems.title),expression:"shownItems.title"}],staticClass:"block-blog-list-item__text font-primary"},[_vm._v(" "+_vm._s(_vm.post.meta.title)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.shownItems.description),expression:"shownItems.description"}],staticClass:"block-blog-list-item__text font-secondary"},[_vm._v(" "+_vm._s(_vm.post.meta.description)+" ")]),_c('BlockBlogListItemMeta',_vm._b({},'BlockBlogListItemMeta',{
				minReadText: _vm.$t('builder.blog.blogPost.minRead'),
				authorName: _vm.authorFullName,
				minutesAmount: _vm.post.customData.minutesToRead,
				date: _vm.post.customData.date,
				showAvatar: _vm.shownItems.avatar,
				showName: _vm.shownItems.authorFullName,
				showDate: _vm.shownItems.date,
				showMinutes: _vm.shownItems.minutesToRead
			},false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }