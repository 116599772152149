<template>
	<div class="empty-block__wrapper">
		<template v-if="mode === 'with-button'">
			<div class="empty-block">
				<p class="z-body-small z-body-small--strong empty-block__title">
					{{ $t('builder.blog.blockBlogListEmptyBlock.title') }}
				</p>
				<p class="z-body-small empty-block__subtitle">
					{{ $t('builder.blog.blockBlogListEmptyBlock.subtitle') }}
				</p>
				<ZyroButton
					theme="primary"
					icon-left="plus"
					:title="$t('builder.blog.blockBlogListEmptyBlock.addNewPost')"
					class="empty-block__button"
					@click="$emit('add-post')"
				>
					{{ $t('builder.blog.blockBlogListEmptyBlock.addNewPost') }}
				</ZyroButton>
			</div>
		</template>
		<template v-else-if="mode === 'no-posts'">
			<div class="empty-block">
				<p class="z-body-small z-body-small--strong empty-block__title">
					{{ $t('builder.blog.blockBlogListEmptyBlock.noPosts') }}
				</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		mode: {
			type: String,
			default: 'with-button',
			validator: (mode) => [
				'with-button',
				'no-posts',
			].includes(mode),
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@user/components/block-blog/block-blog-list-empty-block/BlockBlogListEmptyBlock';

.empty-block {
	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 24px;
	}

	&__button {
		pointer-events: initial;
		cursor: pointer;
	}
}
</style>
