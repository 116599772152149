<template>
	<div
		:id="blockId"
		ref="blogList"
		class="block-blog-list"
		data-qa="builder-section-blog"
	>
		<Transition name="fade">
			<div
				v-if="filteredCategoryId"
				class="block-blog-list__filter"
			>
				{{ website.blogCategories[filteredCategoryId].name }}
				<ZyroButton
					class="block-blog-list__filter-button"
					@click="filteredCategoryId = null"
				>
					{{ $t('builder.blog.blockBlogList.showAllPosts') }}
				</ZyroButton>
			</div>
		</Transition>
		<div
			v-if="currentPageItems.length"
			class="block-blog-list__list"
			:class="{ 'block-blog-list__list--one-col': postColumnCount === 1 }"
		>
			<BlockBlogListItem
				v-for="(post, index) in currentPageItems"
				:key="`post-${index}`"
				data-qa="blog-list-item"
				:post="post"
				:author-full-name="userFullName"
				:cover-object-fit="data.settings.styles['cover-object-fit']"
				:shown-items="data.settings.shownItems"
				@filter-category="filteredCategoryId = $event, scrollToTop()"
				@post-click="handleClick(post)"
			/>
		</div>
		<BlockBlogListEmptyBlock
			v-else
			:mode="mode"
			@add-post="addPost"
		/>
		<ZyroPagination
			class="block-blog-list__pagination"
			:current-page="currentPage"
			:page-count="pageCount"
			@change-page="currentPage = $event, scrollToTop()"
		/>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import BlockBlogListEmptyBlock from '@/components/block-blog/block-blog-list-empty-block/BlockBlogListEmptyBlock.vue';
import BlockBlogListItem from '@/components/block-blog/block-blog-list-item/BlockBlogListItem.vue';
import ZyroPagination from '@/components/global/ZyroPagination.vue';
import { BLOG_ROUTE } from '@/router';

export default {
	components: {
		BlockBlogListItem,
		BlockBlogListEmptyBlock,
		ZyroPagination,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	/*
	 * man komenntas
	 * asdadasd
	 */
	data() {
		return {
			currentPage: 1,
			filteredCategoryId: null,
		};
	},
	computed: {
		...mapState(['website']),
		...mapState('user', ['user']),
		...mapGetters('pages', [
			'publishedBlogPages',
			'blogPages',
		]),
		postColumnCount() {
			return Number.parseInt(this.data.settings.styles['post-column-count'], 10);
		},
		userFullName() {
			return this.user?.fullName || this.$t('common.name');
		},
		/*
		 * Create a posts object from original one, but exclude posts that don't have shown categories.
		 * or, if filtered category is set, show only filtered posts.
		 * If the main categories array is empty, show all posts.
		 */
		posts() {
			const {
				categories,
				showAllPosts,
				showWithoutCategories,
			} = this.data.settings;

			const posts = showAllPosts ? this.publishedBlogPages : Object.fromEntries(
				Object.entries(this.publishedBlogPages)
					.filter(([, page]) => {
						const isPostIncludedInList = categories.some(
							(listCategory) => page.customData.categories.includes(listCategory),
						);
						const showUncategorized = showWithoutCategories && page.customData.categories.length === 0;

						return showUncategorized || isPostIncludedInList;
					}),
			);

			if (this.filteredCategoryId) {
				return Object.fromEntries(Object.entries(posts)
					.filter(([, post]) => post.customData.categories.includes(this.filteredCategoryId)));
			}

			return posts;
		},
		sortedPosts() {
			return Object.values(this.posts).sort(
				(a, b) => Date.parse(b.customData.date) - Date.parse(a.customData.date),
			);
		},
		currentPageItems() {
			return this.sortedPosts.slice(
				(this.currentPage - 1) * this.data.settings.postsPerPage,
				this.currentPage * this.data.settings.postsPerPage,
			);
		},
		pageCount() {
			return Math.ceil(Object.keys(this.posts).length / this.data.settings.postsPerPage);
		},
		mode() {
			return Object.keys(this.blogPages).length === 0 ? 'with-button' : 'no-posts';
		},
	},
	watch: {
		// If current page is not the first page and there are no items to show, go back one page
		currentPageItems(value) {
			if (value.length === 0 && this.currentPage > 1) {
				this.currentPage -= 1;
			}
		},
	},
	methods: {
		...mapActions('pages', [
			'addPage',
			'setCurrentPage',
		]),
		addPost() {
			const postId = nanoid();

			this.addPage({
				type: 'blog',
				payload: { pageId: postId },
			});
			window.hj('identify', this.user.id, { 'builder.blog.create_new_post': true });
			this.$router.push({
				name: BLOG_ROUTE,
				params: { postId },
			});
		},
		// If block is not fully visible, scroll to the top of it.
		scrollToTop() {
			const headerHeight = 50;
			const containerTopCoordinate = this.$refs.blogList.getBoundingClientRect().top;

			if (containerTopCoordinate > 0) {
				return;
			}

			window.scrollBy({
				top: containerTopCoordinate - headerHeight,
				behavior: 'smooth',
			});
		},
		handleClick(post) {
			const pageId = Object.keys(this.publishedBlogPages).find(
				(key) => this.publishedBlogPages[key].path === post.path,
			);

			/*
			 * We push to the page first and then set the current page so the
			 * flash bug would be less visible.
			 */
			this.$router.push({
				name: BLOG_ROUTE,
				params: { postId: pageId },
			});
			this.setCurrentPage({
				type: 'blog',
				payload: { pageId },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@user/components/block-blog/block-blog-list/BlockBlogList';
</style>
